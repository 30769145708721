<!--
 * @Description: 区域组件测试
 * @Author: 小广
 * @Date: 2020-04-17 16:17:46
 * @LastEditors: 小广
 * @LastEditTime: 2020-04-20 11:17:55
 -->
<template>
  <div class="view-wrapper">
    <area-select filterable :codes.sync="selectedArea" :address.sync="address" @change="change" :width="300" :clearable="false"> </area-select>
    <div class="block" style="margin-top: 10px;">
      <span class="address">
        选择的区域是：{{ `code是：${selectedArea};&nbsp;&nbsp;&nbsp;&nbsp;内容是：${selectedAreaStr}` }}
      </span>
    </div>
    <div class="block">
      <span class="address">详细地址是：{{ address }}</span>
    </div>
  </div>
</template>
<script>

import { AreaSelect } from '@/components/bussiness'

export default {

  components: {
    AreaSelect
  },
  data () {
    return {
      selectedArea: [], // ['110000', '110100', '110105'],
      address: '',
      selectedAreaStr: ''
    }
  },

  mounted () {
    let _this = this
    setTimeout(() => {
      _this.selectedArea = ['110000', '110100', '110105']
    }, 1000)
  },

  methods: {
    change (selectedArea) {
      // 取到省市区的对象
      if (selectedArea) {
        let areaStr = ''
        if (selectedArea.province) {
          areaStr = selectedArea.province.name
        }

        if (selectedArea.city) {
          areaStr = `${areaStr} - ${selectedArea.city.name}`
        }

        if (selectedArea.area) {
          areaStr = `${areaStr} - ${selectedArea.area.name}`
        }
        this.selectedAreaStr = areaStr
      }

      /* selectedArea结构如下
      let selectedArea = {
        province: {
          name: "省",
          code: "省code",
          id: "省id"
        },
        city: {
          name: "市",
          code: "市code",
          id: "市id"
        },
        area: {
          name: "区县",
          code: "区县code",
          id: "区县id"
        }
      }
      */
    }
  }
}
</script>

<style lang="scss" scoped>
.view-wrapper {
  margin-top: 20px;
  display: block;
  justify-content: column;

  .block {
    display: flex;
    height: 40px;

    .address {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
  }
}
</style>
