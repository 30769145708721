var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-wrapper" },
    [
      _c("area-select", {
        attrs: {
          filterable: "",
          codes: _vm.selectedArea,
          address: _vm.address,
          width: 300,
          clearable: false,
        },
        on: {
          "update:codes": function ($event) {
            _vm.selectedArea = $event
          },
          "update:address": function ($event) {
            _vm.address = $event
          },
          change: _vm.change,
        },
      }),
      _c(
        "div",
        { staticClass: "block", staticStyle: { "margin-top": "10px" } },
        [
          _c("span", { staticClass: "address" }, [
            _vm._v(
              "\n      选择的区域是：" +
                _vm._s(
                  `code是：${_vm.selectedArea};    内容是：${_vm.selectedAreaStr}`
                ) +
                "\n    "
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "block" }, [
        _c("span", { staticClass: "address" }, [
          _vm._v("详细地址是：" + _vm._s(_vm.address)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }